import { ClassData } from '@models/class-data';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { BillingAddress } from '@models/payments/billing-address.model';
import { EventAttendeeTicket } from './event-attendee-ticket.model';
import { EventAttendeeTicketOrderStatus } from './event-attendee-ticket-order-status.enum';
import { NumberUtils } from '@utils/number-utils';
import { EventAttendeeTicketOrderRefund } from './event-attendee-ticket-order-refund.model';
import moment from 'moment';

export class EventAttendeeTicketOrder {
  id: number;
  createdAt?: Date;

  uuid: string;

  orderNumber: string;

  eventId: number;
  eventAttendeeId?: number;
  eventAttendee?: EventAttendee;

  eventAttendeeTickets: EventAttendeeTicket[];

  stripePaymentIntentSecret: string;
  stripeCustomerSessionClientSecret?: string;
  stripeConnectedAccountId?: string;

  status: EventAttendeeTicketOrderStatus;

  checkoutSessionExpirationDate?: Date;

  totalPrice: number;
  basePrice: number;

  billingAddressId?: number;
  billingAddress?: BillingAddress;

  refund?: EventAttendeeTicketOrderRefund;

  datePaid?: Date;

  constructor(json: ClassData<EventAttendeeTicketOrder>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.uuid = json.uuid;
    this.orderNumber = json.orderNumber;

    this.eventId = +json.eventId;
    if (json.eventAttendeeId !== undefined) {
      this.eventAttendeeId = +json.eventAttendeeId;
    }

    this.eventAttendeeTickets = this.mapEventAttendeeTickets(
      json.eventAttendeeTickets,
    );

    if (json.eventAttendee) {
      this.eventAttendee = new EventAttendee(json.eventAttendee);
    }

    this.stripePaymentIntentSecret = json.stripePaymentIntentSecret;
    this.stripeCustomerSessionClientSecret =
      json.stripeCustomerSessionClientSecret;
    if (json.stripeConnectedAccountId) {
      this.stripeConnectedAccountId = json.stripeConnectedAccountId;
    }

    this.status = json.status;

    if (json.checkoutSessionExpirationDate) {
      this.checkoutSessionExpirationDate = new Date(
        json.checkoutSessionExpirationDate,
      );
    }

    this.totalPrice = +json.totalPrice;
    this.basePrice = json.basePrice;

    if (json.billingAddressId) {
      this.billingAddressId = +json.billingAddressId;
    }
    if (json.billingAddress) {
      this.billingAddress = new BillingAddress(json.billingAddress);
    }

    if (json.refund) {
      this.refund = new EventAttendeeTicketOrderRefund(json.refund);
    }

    if (json.datePaid) {
      this.datePaid = new Date(json.datePaid);
    }
  }

  getDisplayTotalPrice(): string {
    return `${NumberUtils.roundToUpTo2Decimals(this.totalPrice / 100.0)} €`;
  }

  getTicketQuantityMap() {
    const ticketQuantityMap: {
      [id: number]: { ticket: EventAttendeeTicket; quantity: number };
    } = {};

    this.eventAttendeeTickets.forEach((ticket) => {
      const ticketId = ticket.eventTicketId;
      if (ticketQuantityMap[ticketId]) {
        ticketQuantityMap[ticketId].quantity += 1;
      } else {
        ticketQuantityMap[ticketId] = { ticket: ticket, quantity: 1 };
      }
    });

    return Object.values(ticketQuantityMap);
  }

  getDateDisplayFileName(): string {
    if (!this.datePaid) {
      return 'date-of-payment';
    }

    return moment(this.datePaid).format('yyyy-mm-DD');
  }

  isProcessing(): boolean {
    return this.status === EventAttendeeTicketOrderStatus.PROCESSING;
  }

  isPaidOrProcessing(): boolean {
    return (
      this.status === EventAttendeeTicketOrderStatus.SUCCEEDED ||
      this.status === EventAttendeeTicketOrderStatus.PROCESSING
    );
  }

  hasPaymentIntent(): boolean {
    return this.stripePaymentIntentSecret !== '';
  }

  private mapEventAttendeeTickets(
    tickets: EventAttendeeTicket[],
  ): EventAttendeeTicket[] {
    return tickets.map((it) => new EventAttendeeTicket(it));
  }
}
