import { ClassData } from '@models/class-data';
import { EventAttendeeTicketOrderRefundStatus } from '@models/events/event-attendee.ticket-order-refund-status.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';

export class EventAttendeeTicketOrderRefund {
  id: number;
  createdAt?: Date;
  eventAttendeeTicketOrderId?: number;
  eventAttendeeTicketOrder?: EventAttendeeTicketOrder;
  status: EventAttendeeTicketOrderRefundStatus;
  refundFee: number;
  amount: number;
  isRequestedByAttendee: boolean;
  reason: string;
  message: string;
  date?: Date;

  constructor(json: ClassData<EventAttendeeTicketOrderRefund>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventAttendeeTicketOrderId = json.eventAttendeeTicketOrderId;
    if (json.eventAttendeeTicketOrder) {
      this.eventAttendeeTicketOrder = new EventAttendeeTicketOrder(
        json.eventAttendeeTicketOrder,
      );
    }

    this.status = json.status;
    this.refundFee = json.refundFee;
    this.amount = json.amount;
    this.isRequestedByAttendee = json.isRequestedByAttendee;
    this.reason = json.reason;
    this.message = json.message;

    if (json.date) {
      this.date = new Date(json.date);
    }
  }
}
