import { ClassData } from '@models/class-data';

export class EventHubspotMapping {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  eventId?: number;

  // used to map questions
  eventAttendeeQuestionId?: number;

  // used to map form elements
  fieldName?: string;

  hubspotPropertyName?: string;

  constructor(json: ClassData<EventHubspotMapping>) {
    this.id = json.id;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.eventId = json.eventId;
    this.eventAttendeeQuestionId = json.eventAttendeeQuestionId;
    this.fieldName = json.fieldName;
    this.hubspotPropertyName = json.hubspotPropertyName;
  }
}
