import { ClassData } from '@models/class-data';

export enum HubspotPropertyType {
  NUMBER = 'number',
  ENUMERATION = 'enumeration',
  STRING = 'string',
}

export enum HubspotPropertyFieldType {
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  BOOLEAN_CHECKBOX = 'booleancheckbox',
  TEXTAREA = 'textarea',
  TEXT = 'text',
  PHONE_NUMBER = 'phonenumber',
}

export class HubspotPropertyOption {
  name: string;
  label: string;
  type: HubspotPropertyType;
  fieldType: HubspotPropertyFieldType;

  constructor(json: ClassData<HubspotPropertyOption>) {
    this.name = json.name;
    this.label = json.label;
    this.type = json.type as HubspotPropertyType;
    this.fieldType = json.fieldType as HubspotPropertyFieldType;
  }
}
